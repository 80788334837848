.card {
  margin-block-start: var(--bui_spacing_4x);
}

.image img {
  height: 100%;
  flex: 1;
}

.content {
  padding: var(--bui_spacing_4x);
  display: flex;
  flex-direction: column;
  gap: var(--bui_spacing_2x);
}

.description {
  margin-block-start: var(--bui_spacing_2x);
}

.button {
  margin-block-start: var(--bui_spacing_2x);
  width: fit-content;

  &:hover {
    cursor: pointer;
  }
}
