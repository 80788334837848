.anchorContainer {
  text-decoration: none;
  color: inherit;
}

.image {
  border-radius: var(--bui_border_radius_200);
}

.textContainer {
  margin-block-start: var(--bui_spacing_4x);
}

.textLine {
  margin-block-start: var(--bui_spacing_half);
}
