.banner {
  padding: 0;

  &:hover {
    box-shadow: var(--bui_shadow_100);
    transition: box-shadow 0.15s ease;
  }

  .top {
    height: 148px;

    img {
      width: 100%;
      height: 148px;
    }
  }

  .bottom {
    padding: var(--bui_spacing_4x)
  }

  .icon {
    padding-inline-end: var(--bui_spacing_1x);
  }

  .city {
    margin-block-start: var(--bui_spacing_2x);
  }

  .priceWrapper {
    margin-block-start: var(--bui_spacing_3x);

    .price {
      line-height: normal; /* stylelint-disable-line */
    }
  }
}

