.container {
  margin: var(--bui_spacing_8x) auto;
  align-items: start
}

.title {
  margin-block-end: var(--bui_spacing_4x);
}

.grid {
  margin-block-start: var(--bui_spacing_8x);
}

.carousel li {
  width: 89%;
}

.mobileColumn {
  display: flex;
  flex-direction: column;
  gap: var(--bui_spacing_6x);
  margin-block-start: var(--bui_spacing_6x);
}
