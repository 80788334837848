.container {
  margin: var(--bui_spacing_8x) auto;
}

.mobileContainer {
  width: 100%;
  margin: var(--bui_spacing_8x) 0 var(--bui_spacing_4x);
  justify-content: space-between;

  & > div {
    max-width: 100vw;
  }
}
