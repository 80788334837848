.cardsWrapper {
  padding-block-start: var(--bui_spacing_4x);
  display: flex;
  flex-wrap: wrap;
  gap: var(--bui_spacing_4x);
  width: 100%;

  .item {
    flex: 1;
    align-self: stretch;
    max-width: calc((100% - var(--bui_spacing_8x)) / 3);
    border-radius: var(--bui_border_radius_200);

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    &:hover {
      box-shadow: var(--bui_shadow_100);
      transition: box-shadow 0.15s ease;
      cursor: pointer;
    }
  }
}

.cardContent {
  padding: var(--bui_spacing_4x);
}

.button {
  margin-block-start: var(--bui_spacing_3x);
  max-width: 328px;

  &:hover {
    cursor: pointer;
  }
}
