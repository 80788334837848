.image {
  width: 64px;
  height: 64px;
  border-radius: var(--bui_border_radius_100);
  flex-shrink: 0;
}

.text {
  flex: 1;
}
