:root {
  --WINTER_DEALS_IMAGE: url("https://q-xx.bstatic.com/xdata/images/xphoto/2880x868/439571029.jpeg?k=6441bccd549bb95b9c7077c1f0ab1fd4efbedc13c26a32c544199c037133b819&o=");
}

/* stylelint-disable-next-line bui-optimal-typography/validate-typography */
.title {
  font-weight: 700;
  font-family: "Avenir Next", BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  margin: 0;
}

/* stylelint-disable-next-line bui-optimal-typography/validate-typography */
.subtitle {
  font-weight: 400;
  font-family: "Avenir Next", BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  max-width: 610px;
}

.box {
  display: flex;
  align-items: center;
  width: 100%;
  height: 398px;
  padding-block-start: 0;
}

.container {
  background-image: linear-gradient(90deg, rgb(26, 26, 26) 30%, transparent 60%), var(--WINTER_DEALS_IMAGE);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;

  > div {
    height: 100%;
    justify-content: center;
  }
}

/* stylelint-disable-next-line */
@media screen and (max-width: 800px) {
  .container {
    background-image: linear-gradient(90deg, rgb(26, 26, 26) 30%, transparent 100%), var(--WINTER_DEALS_IMAGE);
  }
}
