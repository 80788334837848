[data-device="desktop"] .grid {
  margin-block-start: var(--bui_spacing_8x) !important;
  margin-block-end: var(--bui_spacing_16x) !important;
}

[data-device="mdot"] .grid {
  margin-block-start: var(--bui_spacing_4x) !important;
  margin-block-end: var(--bui_spacing_4x) !important;
}

[data-device="desktop"] .grid__no_mt {
  margin-block-start: 0 !important;
}

[data-device="mdot"] .grid__no_mt {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

.container {
  background-color: var(--bui_color_background_base_alt);
}

.disclaimerContainer {
  margin-block-start: var(--bui_spacing_4x);
}

.disclaimerContainer__small {
  margin-block-start: var(--bui_spacing_1x);
  padding-inline-start: var(--bui_spacing_8x);
  padding-inline-end: var(--bui_spacing_8x);
}

.disclaimerContainer__small > div {
  min-width: auto;
}

[data-device="mdot"] .uspContainer {
  margin-block-end: var(--bui_spacing_4x) !important;
}

[data-device="mdot"] .uspContainer__padding {
  padding: 0 var(--bui_spacing_4x) !important;
}

.uspImageWrapper {
  width: 80px;
}

.icon_wrapper:hover {
  cursor: pointer;
}
