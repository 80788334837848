.carousel {
  margin-block-start: var(--bui_spacing_4x);
}

.disclaimer {
  margin-block-start: var(--bui_spacing_6x);
}

/* stylelint-disable-next-line bui-media-queries/validate-media */
@media (max-width: 1024px) {
  .mobileContainer {
    padding-inline-end: 0;
  }
}
