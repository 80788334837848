.banner {
  padding: 0;

  .left {
    width: 120px;
    min-height: 128px;
    align-self: stretch;

    img {
      min-height: 128px;
      height: 100%;
      width: 100%;
    }

    .icon {
      padding-inline-end: var(--bui_spacing_1x);
    }
  }

  .right {
    flex-shrink: 1;
    padding: var(--bui_spacing_4x);

    .city {
      margin-block-start: var(--bui_spacing_2x);
    }
  }

  .priceWrapper {
    margin-block-start: var(--bui_spacing_3x);

    .price {
      line-height: normal; /* stylelint-disable-line */
    }
  }
}


