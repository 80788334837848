.title {
  margin-block-end: var(--bui_spacing_4x);
}

.faqCardContainer {
  display: flex;
  height: 100%;
}

.faqCard {
  border-radius: var(--bui_border_radius_200) !important;
}

.faqCard .question {
  margin-block-end: var(--bui_spacing_1x);
}
