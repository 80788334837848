.carousel {
  max-width: 100vw;
}

.tabPanelContent {
  padding-block-start: var(--bui_spacing_4x);
}

.singleCarouselTitle {
  margin-block-end: var(--bui_spacing_6x);
}

.tabbedCarouselTitle {
  margin-block-end: var(--bui_spacing_3x);
}

.tabs {
  margin-block-end: var(--bui_spacing_6x);
}
