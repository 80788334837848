.trigger {
  height: var(--bui_spacing_4x);
}

.popover {
  border: 0;
  padding: 0;
}

.info_wrapper {
  flex: 0;
}

.icon_wrapper:hover {
  cursor: pointer;
}

.disclaimerContainer__small {
  margin-block-start: var(--bui_spacing_1x);
  padding-inline-start: var(--bui_spacing_8x);
  padding-inline-end: var(--bui_spacing_8x);
}

.disclaimerContainer__small > div {
  min-width: auto;
}

.icon {
  display: flex;
}
